import { combineReducers } from "redux";
import helloReducer from "./helloReducer";
import authentication from "./authReducer";
import user from "./userReducer";
import multiLanguage from "./multiLanguageReducer";
import showLoading from "./loadingReducer";
// import course from "./courseReducer";
import sideBarShow from "./sideBarReducer";
import setting from "./settingReducer";

const rootReducer = combineReducers({
    helloReducer,
    showLoading,
    authentication,
    user,
    multiLanguage,
    // course,
    sideBarShow,
    setting
});

export default rootReducer;
