import * as actions from "../Constants/actionTypes";

const initState = {
    isShowLoading: false,
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AT_LOADING_SHOW:
            return {
                ...state,
                isShowLoading: true
            };
        case actions.AT_LOADING_HIDE:
            return {
                ...state,
                isShowLoading: false
            };

        default:
            return state;
    }
};

export default myReducer;
