import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; 
import Backend from 'i18next-xhr-backend';
// import LocizeBackend from 'i18next-locize-backend';
 
// https://www.robinwieruch.de/react-internationalization
// https://react.i18next.com/latest/using-with-hooks
// https://react.i18next.com/latest/migrating-v9-to-v10
// https://www.digitalocean.com/community/tutorials/react-create-i18n-module
i18n
  // .use(LocizeBackend)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
 
    lng: 'vi',
    fallbackLng: 'vi',
    whitelist: ['vi', 'en', 'de'],
 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });
 
export default i18n;