import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// user
export const changePasswordApi = (data) => {
    return axiosService.postWithToken(urlApis.API_CHANGE_PASSWORD_POST, data);
};

export const updateProfileApi = (data) => {
    return axiosService.postWithToken(urlApis.API_UPDATE_PROFILE_POST, data);
};

export const getUserProfileApi = () => {
    return axiosService.getWithToken(urlApis.API_USER_PROFILE_GET);
};

export const getUserAllHistoryLogApi = (paramString) => {
    return axiosService.getWithToken(urlApis.API_USER_HISTORY_LOG_GET_ALL + "?" + paramString);
};

export const uploadAvatarUserApi = (data) => {
    return axiosService.patchUploadImageWithToken(urlApis.API_UPLOAD_AVATAR_PROFILE_PATCH, data);
};
export const uploadAvatarFaceIDApi = (data) => {
    return axiosService.postUploadImageWithToken(urlApis.API_UPLOAD_AVATAR_FACE_ID_PATCH, data);
};