import { fork } from "redux-saga/effects";
import helloSaga from "./helloSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
// import courseSaga from "./courseSaga";

export default function* rootSaga() {
    // yield fork(courseSaga);
    yield fork(userSaga); 
    yield fork(authSaga); 
    yield fork(helloSaga);    
}


