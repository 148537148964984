import { get, includes, filter } from "lodash";

export const ROLES = {
    MEMBER: "member_group",
    ADMIN: "admin_group",
    MANAGER: "manager_group",
    STAFF: "staff_group",
}

export const PERMISSION_ACTION = {
    ADD: 'add',
    CHANGE: 'change',
    DELETE: 'delete',
    VIEW: 'view'
}

export const PERMISSION_MODEL = {
    PROFILE: 'profile',
    COUNTRY: 'country',
    LOG_ENTRY: 'log entry',
    // ...
}

export const hasPermissionWithRouter = (allows = ['*'], profile) => {
    /* 
        ===============================================
        'allows' is array fix when create route. EX: allows: [ROLES.MEMBER, ROLES.COACH],
        'profile' is object have a groups array get when user login.
        ===============================================
        - Allow if have '*'
        - Allow if profile groups array has item on allows array
    */
    let groups = get(profile, 'groups', []);
    return includes(allows, '*') || (groups && groups.length > 0 && filter(groups, v => (includes(allows, v))).length > 0);
};

export const canPermission = (action = '', model = '', profile) => {
    /* 
        Only allow if have permission in profile object when user login
        EX:
            profile: {
                permissions: [
                    'Can add profile',
                    'Can change profile',
                    'Can delete profile',
                    'Can view profile'
                ],
            }
        'action' is string define on PERMISSION_ACTION array
        'model' is string define on PERMISSION_MODEL array
    */
    let permissions = get(profile, 'permissions', []);
    return action && model && permissions && permissions.length > 0 && includes(permissions, `Can ${action} ${model}`);
};

export const isAdmin = groups => {
    /* 
        Check user is member
    */
    return !!includes(groups, ROLES.ADMIN)
};

export const isMember = groups => {
    /* 
        Check user is member
    */
    return !!includes(groups, ROLES.MEMBER)
};

export const isManager = groups => {
    /* 
        Check user is manager
    */
    return !!includes(groups, ROLES.MANAGER)
};

export const isStaff = groups => {
    /* 
        Check user is staff
    */
    return !!includes(groups, ROLES.STAFF)
};
