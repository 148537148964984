import * as actions from "../Constants/actionTypes";

const initState = {
    isLoading: false,
    lang: 'vi',
};

const multiLanguageReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AT_CHANGE_MULTI_LANGUAGE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                lang: action.payload
            };
        }
        default:
            return state;
    }
};

export default multiLanguageReducer;
