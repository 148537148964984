import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { Switch, HashRouter } from "react-router-dom";
import { SnackbarContainer } from "uno-material-ui";

//
import store from "../Store/indexStore";
import history from "../Utils/historyUtils";
import frontendRouters from "../Routes/FrontendRouteList";
import backendRouters from "../Routes/BackendRouteList";
//
import "./../TempCoreUI/scss/style.scss";
import { icons } from "./../TempCoreUI/assets/icons";
//
const FrontendRoute = lazy(() => import("../Routes/FrontendRoute"));
const AdminRoute = lazy(() => import("../Routes/AdminRoute"));
const FrontendLayout = lazy(() => import("../Frontend/Layout/FrontendLayout"));
const NotFoundPage = lazy(() =>
  import("../Frontend/Pages/NotFoundPage/NotFoundPage")
);
// const Main = lazy(() => import("./Main"));

//
React.icons = icons;

const loadingWaiting = (
  <div className="spinner-grow text-dark text-center" role="status">
    <span className="visually-hidden">...</span>
  </div>
);

function App() {
  return (
    <Suspense fallback={loadingWaiting}>
      <Provider store={store}>
        {/* <Main /> */}
        <HashRouter history={history}>
          <Switch>
            {frontendRouters.map((v, k) => (
              <FrontendRoute key={k} {...v} />
            ))}
            {backendRouters.map((v, k) => (
              <AdminRoute key={k} {...v} />
            ))}
            <FrontendRoute component={NotFoundPage} layout={FrontendLayout} />
          </Switch>
          <SnackbarContainer />
        </HashRouter>
      </Provider>
    </Suspense>
  );
}

export default App;
