import * as actions from "../Constants/actionTypes";

// change password

export const changePasswordFailureAction = (payload) => ({
    type: actions.AT_CHANGE_PASSWORD_FAILURE,
    payload,
});

export const changePasswordSuccessAction = (payload) => ({
    type: actions.AT_CHANGE_PASSWORD_SUCCESS,
    payload,
});

export const changePasswordRequestAction = (data) => ({
    type: actions.AT_CHANGE_PASSWORD_REQUEST,
    payload: { data },
});

// update profile

export const updateProfileFailureAction = (payload) => ({
    type: actions.AT_UPDATE_PROFILE_FAILURE,
    payload,
});

export const updateProfileSuccessAction = (payload) => ({
    type: actions.AT_UPDATE_PROFILE_SUCCESS,
    payload,
});

export const updateProfileRequestAction = (data) => ({
    type: actions.AT_UPDATE_PROFILE_REQUEST,
    payload: { data },
});

// get profile
export const setUserProfileToNullAction = () => ({
    type: actions.AT_USER_SET_PROFILE_NULL,
});

export const getUserProfileFailureAction = (playload) => ({
    type: actions.AT_USER_GET_PROFILE_FAILURE,
    playload,
});

export const getUserProfileSuccessAction = (playload) => ({
    type: actions.AT_USER_GET_PROFILE_SUCCESS,
    playload,
});

export const getUserProfileRequestAction = (data) => ({
    type: actions.AT_USER_GET_PROFILE_REQUEST,
    payload: { data },
});

// update avatar user

export const uploadAvatarUserFailureAction = (payload) => ({
    type: actions.AT_UPLOAD_AVATAR_USER_FAILURE,
    payload,
});

export const uploadAvatarUserSuccessAction = (payload) => ({
    type: actions.AT_UPLOAD_AVATAR_USER_SUCCESS,
    payload,
});

export const uploadAvatarUserRequestAction = (data) => ({
    type: actions.AT_UPLOAD_AVATAR_USER_REQUEST,
    payload: { data },
});
