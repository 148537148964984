export const STATUS_CODE = {
    HTTP_200_OK: 200,
    HTTP_201_CREATED: 201,
    HTTP_204_NO_CONTENT: 204,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    HTTP_401_UNAUTHORIZED: 401,
    NOT_ACCEPTABLE: 406,
    REQUEST_TIMEOUT: 408,
    // 
    HTTP_ME_450_EMAIL_EXIST: 450,
    HTTP_ME_451_EMAIL_DOES_NOT_EXIST: 451,
    HTTP_ME_452_ACCOUNT_IS_NOT_ACTIVATED: 452,
    HTTP_ME_453_ACCOUNT_IS_ACTIVATED: 453,
    HTTP_ME_454_OLD_PASSWORD_IS_INCORRECT: 454,
    HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED: 455,
    HTTP_ME_456_SLUG_EXIST: 456,
    HTTP_ME_457_CODE_EXIST: 457,
    HTTP_ME_458_DUPLICATE: 458,
    HTTP_ME_459_SOME_THING_EXIST: 459,
    HTTP_ME_460_SOME_THING_INVALID: 460,
    HTTP_ME_461_NOT_PAID: 461,
    // 
    INTERNAL: 500,
    // EMAIL_EXIST: 600,
    // PHONE_EXIST: 601,
    TOKEN_NOT_VALID: 4010,
};
